
.categoria-card {
  margin-bottom: 20px; // ou qualquer valor desejado para a distância vertical
}

.custom-link {
  color: #ec2300;
  /* Set your desired color */
  text-decoration: none;
  /* Remove underline */
  cursor: pointer;
  /* Set cursor to pointer */
}

/* Se você estiver usando CSS */
.small-form {
  font-size: 13px;
  /* ou o tamanho desejado */
}

/* Se você estiver usando SCSS */
.small-form {
  font-size: 13px; // ou o tamanho desejado
}

.Form.Control.Feedback {
  color: red;
  /* ou outra cor de destaque */
  font-size: 13px;
  visibility: visible;
  /* ou deixe em branco para usar o padrão */
}

.capa-imagem {
  width: 1300px;
  height: 300px;
  object-fit: cover;
  border-radius: 10px; /* Todos os cantos com raio de 10 pixels */
}

.avatar-imagem {
  position: absolute;
  top: 100%;
  left: 18%;
  transform: translate(-50%, -50%);
  width: 15%;
  height: auto;
  max-width: 50%;
  border: 3px solid white;
  box-sizing: border-box;
}

  /* Estilos para smartphones */
  @media (max-width: 768px) {
    .capa-imagem {
      width: 768px;
      height: 125px;
      object-fit: cover;
    }

    .avatar-imagem {
      position: absolute;
      top: 95%;
      left: 23%;
      transform: translate(-50%, -50%);
      width: 25%;
      height: auto;
      max-width: 70%;
      border: 3px solid white;
      box-sizing: border-box;
    }
  }

  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  main {
    flex: 1;
  }

  .capa-imagem {
    border-radius: 8px;
    /* ou o valor desejado para arredondar as bordas */
  }

  .small-font {
    font-size: 12px; // ou o tamanho desejado
  }

  /* styles.css */
.gray-light-bg {
  background-color: #f5f5f5; /* ou qualquer outra cor cinza claro desejada */
}

.blue-light-bg {
  background-color: #add8e6; /* azul claro */
}

.green-light-bg {
  background-color: #c8e6c9; /* verde claro */
  
}

.yellow-light-bg {
  background-color: #fff9c4; /* amarelo claro */
}

.red-light-bg {
  background-color: #fd364a; /* vermelho claro */
  color: #fff; /* Cor do texto, ajuste conforme necessário */
}

.purple-light-bg {
  background-color: #d1c4e9; /* Roxo claro */
  color: #000; /* Cor do texto, ajuste conforme necessário */
}

.black-light-bg {
  background-color: #333; /* Ou a cor desejada para status específico */
  color: #fff; /* Cor do texto, ajuste conforme necessário */
}

.production-card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 5px;
  text-align: center;
  transition: transform 0.3s ease;
}

.production-card:hover {
  transform: translateY(-5px);
}

.production-card .card-title {
  font-weight: bold;
  margin-bottom: 10px;
  color: #3b5998; /* Cor azul do production */
}

.production-card .card-text {
  font-size: 24px;
  color: #333; /* Cor do texto padrão */
}


.order-list-item {
  transition: transform 0.3s ease;
}

.order-list-item:hover {
  transform: translateY(-5px);
}



//Icones Configuraçãoes Iniciais-------------------------------------------------------------------
.icon-box-wrapper {
  display: flex;
  justify-content: center; /* Centraliza o contêiner horizontalmente */
}

.icon-box-container {
  display: flex;
  padding: 20px;
  overflow-x: auto; /* Adicionando overflow-x para permitir rolagem horizontal */
  -webkit-overflow-scrolling: touch; /* Adicionando suporte de rolagem suave para dispositivos iOS */
}

.icon-card {
  display: flex; /* Torna o ícone e o título flexíveis */
  flex-direction: column; /* Alinha os elementos verticalmente */
  justify-content: center; /* Centraliza verticalmente */
  align-items: center; /* Centraliza horizontalmente */
  text-align: center;
  margin: 0 10px; /* Adiciona margens esquerda e direita */
  cursor: pointer; /* Altera o cursor para um ponteiro */
}

.icon-background {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007bff; /* Azul Principal */
  border-radius: 50%;
  line-height: 100px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.title {
  margin-top: 10px;
  font-size: 16px;
  color: #004e7c; /* Azul Escuro */
  font-weight: bold; /* Aplica negrito à fonte */
  text-align: center;
  max-height: 50px;
  overflow: hidden; /* Evita que o texto ultrapasse a altura máxima */
  word-wrap: break-word; /* Permite que o texto quebre em várias linhas */
}

.icon-inicial {
  font-size: 48px; /* Tamanho do ícone */
  color: #ffffff; /* Branco */
}

@media only screen and (max-width: 768px) {
  .icon-background {
    width: 80px; /* Define o tamanho dos ícones para 80% da largura original */
    height: 80px; /* Define o tamanho dos ícones para 80% da altura original */
    line-height: 80px; /* Ajusta a linha de altura para centralizar o texto */
  }
  .icon-inicial {
    font-size: 34.4px; /* 80% do tamanho original (48px * 0.8) */
  }
  .title {
    font-weight: bold; /* Aplica negrito à fonte */
  }
}


//testes de página -------------------------------------------------------

/* Estilos para telas médias */
@media (min-width: 200px) and (max-width: 800px) {
  .cardapio {
    margin-top: 50px; /* Estilo para telas médias */
  }
}

/* Estilos para telas grandes */
@media (min-width: 801px) and (max-width: 1920px) {
  .cardapio {
    margin-top: 30px; /* Estilo para telas grandes */
  }
}

.no-items-message-container {
  display: flex;
  justify-content: center;
}

.no-items-message {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
  padding: 10px;
  border-radius: 5px;
}

.destacado {
  font-weight: bold;
  font-size: 2.2em; /* Tamanho maior do texto */
}


.whatsapp-button {
  background-color: #128C7E; /* Verde WhatsApp */
  color: white; /* Texto branco para melhor contraste */
  border: none; /* Sem borda */
  padding: 10px 20px; /* Espaçamento interno para o botão */
  text-align: center; /* Alinhamento do texto */
  text-decoration: none; /* Sem sublinhado no texto */
  display: inline-block; /* Tipo de exibição */
  font-size: 16px; /* Tamanho da fonte */
  margin: 4px 2px; /* Margem externa do botão */
  cursor: pointer; /* Cursor em forma de ponteiro */
  border-radius: 5px; /* Bordas arredondadas */
}